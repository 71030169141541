<div class="about_Components">
  <section class="about_Components_firstSector">
    <h1>About me</h1>

    <p>
      Hello, I am a German-speaking frontend developer living in Neuland,
      Paraguay. Driven by the endless possibilities in the IT world, I am
      passionate about creating visually appealing and user-friendly websites
      and applications.
    </p>

    <p>
      I can work effectively remote from Paraguay and have the advantage of
      being available for work in the German-speaking region even during late
      night hours, thanks to the different time zones.
    </p>

    <p>
      I am open-minded and always looking for personal challenges to constantly
      improve my knowledge and skills.
    </p>

    <p>
      With my ability to simplify complex technical challenges into intuitive
      solutions, I help you solve problems in creative ways.
    </p>
  </section>

  <div class="about_Components_secondSector">
    <img
      class="landingPageHeadlineImage"
      src="assets/images/AksenovKonstantinBewerbungsfoto.avif"
      alt="Portrait of developer."
    />
  </div>

  <div class="about_Components_thirdSector">
    <div class="arrowLeft"></div>
  </div>
</div>
