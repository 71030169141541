<div class="headerSelfInner">
  <div class="navLeft">
    <a routerLink="['/']" href="https://www.http://portfolio.dev2k.net/"
      ><img class="pageSelfLogo" src="assets/images/terminal_logo.svg" /></a
    ><span class="fontSyne disableTextSelection pageSelfSlogen">dev2k</span>
  </div>

  <div class="navRight" style="display: flex">
    <nav class="navBarSelf">
      <div class="langDiv" style="display: none">
        <img class="langImage" src="assets/images/de.png" />
        <img class="langImage" src="assets/images/en.png" />
      </div>

      <div class="navBarSelfLinks" style="display: none">
        <a href="#about">{{ "ABOUT" }}</a>
        <a href="#skills">{{ "SKILLS" }}</a>
        <a href="#portfolio">{{ "PORTFOLIO" }}</a>
        <a href="#contact">{{ "CONTACT" }}</a>
      </div>
    </nav>

    <div class="burgerMenuBtn" id="burgerMenuBtn" style="display: flex"></div>
    <svg
      width="25"
      height="25"
      viewBox="0 0 32 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style="display: flex"
    >
      <path
        class="line line1"
        d="M1 1.5127H31"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        class="line line2"
        d="M1 15.5127H31"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        class="line line3"
        d="M1 29.5127H31"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  </div>
</div>
