import { Component } from '@angular/core';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  switchLanguage(lang: string) {}
  toggleMenu(): void {}

  navigateToAboutMe(event: Event) {}
  navigateToMySkills(event: Event) {}
  navigateToPortfolio(event: Event) {}

}
